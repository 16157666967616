
import * as contentfulRuntime$4KWptOD3qz from '/app/node_modules/@nuxt/image/dist/runtime/providers/contentful'
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "images.ctfassets.net",
    "grilldburgerbosssa.blob.core.windows.net"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['contentful']: { provider: contentfulRuntime$4KWptOD3qz, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: undefined }
}
        