<script lang="ts" setup>
  const { orderingAppNavTo } = useOrderingAppNavigation()

  const props = withDefaults(
    defineProps<{
      href: string
      target?: string | null
      isEmbeddedAppLink?: boolean
      ariaLabel?: string
    }>(),
    {
      target: null,
      isEmbeddedAppLink: false,
      ariaLabel: undefined
    }
  )

  const emit = defineEmits<{
    (event: 'click', data: MouseEvent): void
  }>()

  function handleEmbeddedLinkClick() {
    orderingAppNavTo(props.href)

    emit('click', new MouseEvent('click'))
  }
</script>

<template>
  <template v-if="isEmbeddedAppLink">
    <div
      :aria-label="ariaLabel"
      class="c-link"
      @click="handleEmbeddedLinkClick"
      @keydown.enter="handleEmbeddedLinkClick"
    >
      <slot />
    </div>
  </template>

  <template v-else>
    <nuxt-link
      :aria-label="ariaLabel"
      :href="href"
      :target="target ?? undefined"
      class="c-link"
      @click="$emit('click', $event)"
      @keydown.enter="$emit('click', $event)"
    >
      <slot />
    </nuxt-link>
  </template>
</template>

<style lang="scss" scoped>
  .c-link {
    @include typography-link;
    cursor: pointer;
  }
</style>
